import React, { useState, useEffect, forwardRef } from 'react';
import Spinners from 'components/Common/Spinner';
import { 
  formatNumber,
  capitalizeWords,
  calculateTotalRow,
  createQueryParamsFromBreadcrumbs, } from 'utils';
  import { useNavigate } from 'react-router-dom';
import EnhancedTableCredit from './EnhancedTableCredit';
import { columnLabelDict } from 'pages/Credit-Control/Constants/constants';


const DrillDownTableCredit = forwardRef(({ fetchDataFn, fetchDataAtBreadcrumbLimit,destinationPage, initialBreadcrumbs }, ref) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  const [queryParams, setQueryParams] = useState([]);
  const [breadcrumbs, setBreadcrumbs] = useState(initialBreadcrumbs || [{ label: 'Home', field: '' }]);
  const [useEnhancedTable, setUseEnhancedTable] = useState(false);
  
    // Initialize the navigate function
    const navigate = useNavigate();

    useEffect(() => {
      // Assume initialQueryParams is the provided breadcrumbs array
      if(initialBreadcrumbs){
        const params = initialBreadcrumbs
        .filter(crumb => crumb.label !== 'Home') // Remove 'Home' from breadcrumbs
        .reduce((acc, crumb) => {
          acc[crumb.field] = crumb.label;
          return acc;
        }, {});
  
      setQueryParams(params);
      // setBreadcrumbs(initialQueryParams);
      }
    }, []);


  useEffect(() => {
    const fetchData = async () => {
      // console.log(breadcrumbs.length)
      try {
        setLoading(true);
        let response;
        let responseData;
        if (breadcrumbs.length === 5) {
          setUseEnhancedTable(true);
          response = await fetchDataAtBreadcrumbLimit();
          responseData = response?.summary || [];
        } else {
          setUseEnhancedTable(false);
          response = await fetchDataFn(queryParams);
          responseData = response?.summary || [];
        }

        setData(responseData);

        if (responseData?.length > 0) {
          const firstRow = responseData[0];
          const columnKeys = Object.keys(firstRow);
          setColumns(columnKeys);
        } else {
          setColumns([]);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      setLoading(false);
    };
    fetchData();
  }, [queryParams, fetchDataFn, fetchDataAtBreadcrumbLimit, breadcrumbs]);

  const handleRowClick = (field, value) => {
    // Check if the current page is /Credit
    const newParams = {
      ...queryParams,
      ...(field && { [field]: value }),
    };
    setQueryParams(newParams);
    
    setBreadcrumbs((prev) => [
      ...prev,
      { label: value, field },
    ]);
    
    const isCreditPage = window.location.pathname === "/credit-control";
    if (breadcrumbs.length >= 4 && isCreditPage) {
      // After reaching breadcrumb limit, navigate to the appropriate page
      const breadcrumbsState = [
        ...breadcrumbs,
        { label: value, field }
      ];

      if (destinationPage) {
        navigate(destinationPage, { state: { breadcrumbsState } });
      }
    }
  };

  const handleBreadcrumbClick = (index) => {
  const newBreadcrumbs = breadcrumbs.slice(0, index + 1);
  const newParams = createQueryParamsFromBreadcrumbs(newBreadcrumbs);

  setQueryParams(newParams);
  setBreadcrumbs(newBreadcrumbs);
};

  const totalRow = calculateTotalRow(data, columns);

  return (
    <div >
      {/* Breadcrumbs */}
      <nav style={{ marginBottom: '16px' }}>
        <div style={{ display: 'flex', alignItems: 'center', color: '#4a4a4a', fontSize: '10px' }}>
          {breadcrumbs.map((crumb, index) => (
            <React.Fragment key={index}>
              {index > 0 && <span style={{ color: '#9e9e9e', margin: '0 8px' }}>/</span>}
              {index === breadcrumbs.length - 1 ? (
                <span style={{ fontWeight: 'bold' }}>{crumb.label}</span>
              ) : (
                <span
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() => handleBreadcrumbClick(index)}
                >
                  {crumb.label}
                </span>
              )}
            </React.Fragment>
          ))}
        </div>
      </nav>

      {/* Conditional Rendering for Different Tables */}
      {loading ? (
        <div style={{ position: 'relative' }}>
          <Spinners setLoading={setLoading} />
        </div>
      ) : useEnhancedTable ? (
        <EnhancedTableCredit data={data} columns={columns}/>
      ) : (
        data.length === 0 ? (
          <p style={{ textAlign: 'center', color: '#999999' }}>No data available</p>
        ) : (
          <div style={{ overflowX: 'auto' }}>
            <table ref={ref} style={{ minWidth: '100%', backgroundColor: 'white', borderCollapse: 'collapse', border: '1px solid #dcdcdc', fontSize: '12px' }}>
              <thead style={{ backgroundColor: '#f4f4f4' }}>
                <tr>
                  {columns.map((col, index) => (
                    <th key={index} style={{ borderBottom: '2px solid #dcdcdc', padding: '12px', textAlign: 'left', backgroundColor: '#e0e0e0' }}>
                      {columnLabelDict[col] || capitalizeWords(col.replace(/_/g, ' '))}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.map((row, rowIndex) => (
                  <tr
                    key={rowIndex}
                    onClick={() => {
                      if (breadcrumbs.length <= 4) {
                        handleRowClick(Object.keys(row)[0].toLowerCase().replace(' ', '_'), row[Object.keys(row)[0]]);
                      }
                    }}
                    style={{
                      cursor: breadcrumbs.length >= 5 ? 'default' : 'pointer',
                      backgroundColor: '#f9f9f9',
                      '&:hover': { backgroundColor: '#e0e0e0' }
                    }}
                  >
                    {columns.map((col, colIndex) => (
                      <td key={colIndex} style={{ borderBottom: '1px solid #dcdcdc', padding: '12px' }}>
                        {col === columns[0]
                          ? row[col]
                          : typeof row[col] === 'number'
                            ? formatNumber(row[col])
                            : row[col]
                        }
                      </td>
                    ))}
                  </tr>
                ))}
                <tr style={{ backgroundColor: '#f4f4f4' }}>
                  <td style={{ borderTop: '2px solid #dcdcdc', padding: '12px', fontWeight: 'bold' }}>Total</td>
                  {columns.slice(1).map((col, index) => (
                    <td key={index} style={{ borderTop: '2px solid #dcdcdc', padding: '12px', fontWeight: 'bold' }}>
                      {typeof totalRow[col] === 'number'
                        ? formatNumber(totalRow[col])
                        : totalRow[col]
                      }
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        )
      )}
    </div>
  );
});

export default DrillDownTableCredit;
