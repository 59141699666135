import React , {useEffect,useRef} from "react"
import { connect } from "react-redux";

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import { Card,ButtonDropdown,DropdownToggle,DropdownMenu,DropdownItem,CardTitle } from "reactstrap";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';

import SummaryCards from "components/CustomComponents/SummaryCards";
import BookingFilterBar from "./BookingFilterBar";
import { getbookingSummaryReq, getlrPendingDispatchReq, getlrPendingForDispatchHierarchicalReq, getlrPendingForDoorChallenHierarchicalReq, getlrPendingReq } from "./bookingService";
import DrillDownTableBooking from "components/CustomComponents/DrillDownTableBooking";

const Booking = (props) => {

  document.title = "Aurum | Booking";
  const navigate = useNavigate();
  const tableRefs = useRef([]);
  const [filters, setFilters] = useState({});

  const breadcrumbItems = [
    { title: "Home", link: "/dashboard" },
    { title: "Booking", link: "/booking" },
  ]


  const handleCardTitleClick = (destinationPage) => (event) => {
    event.stopPropagation();
    navigate(destinationPage);
  };

  useEffect(() => {
    props.setBreadcrumbItems('Booking' , breadcrumbItems);
  },)

  const applyFilters = (filters) => {
    // console.log("Applied Filters:", filters);
    setFilters(filters || {});
  };
  
  return (
    <React.Fragment >
      <SummaryCards fetchDataFn={getbookingSummaryReq} />
        <div style={{ 
          display: 'flex', 
          flexWrap: 'wrap', 
          gap: '12px' 
        }}>
          {/* <BookingFilterBar onApplyFilters={applyFilters} filters={filters}/> */}
          <Card body style={{ flex:' 1 1 calc(50% - 12px)', boxSizing: 'border-box', minHeight: '300px'}} className="overflow-hidden">
            <CardTitle onClick={handleCardTitleClick("/lrs-pending-dispatch")} style={{ cursor:"pointer"}} className="h4">LRs Pending for Dispatch (Current Branch Wise)</CardTitle>
            <DrillDownTableBooking
              ref={el => tableRefs.current[1] = el}
              fetchDataFn={getlrPendingForDispatchHierarchicalReq} 
              fetchDataAtBreadcrumbLimit={getlrPendingDispatchReq}  
              destinationPage={"/lrs-pending-dispatch"}
              initialQueryParams={filters}
            />
          </Card>
          <Card body style={{ flex:' 1 1 calc(50% - 12px)', boxSizing: 'border-box', minHeight: '300px' }} className="overflow-hidden">
          <CardTitle onClick={handleCardTitleClick("/door-challan")} style={{ cursor:"pointer"}} className="h4">LRs Pending for Door Collection Challan (Booking Branch Wise)</CardTitle>
          <DrillDownTableBooking 
              ref={el => tableRefs.current[0] = el}
              fetchDataFn={getlrPendingForDoorChallenHierarchicalReq} 
              fetchDataAtBreadcrumbLimit={getlrPendingReq}  
              destinationPage={"/door-challan"}
              initialQueryParams={filters}
            />
          </Card>
                    {/* <div style={{ flex: '1 1 calc(50% - 12px)', boxSizing: 'border-box' }}>
            <Card  body className="overflow-hidden" style={{minHeight: '300px'}}>
            <CardTitle onClick={handleCardTitleClick("/pod-uploaded-but-bill-pending")} style={{ cursor:"pointer"}} className="h4">PoD Uploaded But Bill Pending</CardTitle>
              <DrillDownTable 
                ref={el => tableRefs.current[2] = el}
                fetchDataFn={getPoDUploadedButBillPendingReq} 
                fetchDataAtBreadcrumbLimit={getpodUploadedReceivedBillPendingReq} 
                destinationPage={"/pod-uploaded-but-bill-pending"}
                color="#D5F6E3"
                initialQueryParams={filters}
              />
            </Card>
          </div>
          <div style={{ flex: '1 1 calc(50% - 12px)', boxSizing: 'border-box' }}>
            <Card body className="overflow-hidden" style={{minHeight: '300px'}}>
            <CardTitle onClick={handleCardTitleClick("/pod-upload-but-mr-pending")} style={{ cursor:"pointer"}} className="h4">PoD Uploaded But MR Pending</CardTitle>
              <DrillDownTable 
                ref={el => tableRefs.current[3] = el}
                fetchDataFn={getPoDUploadedButMRPendingReq} 
                fetchDataAtBreadcrumbLimit={getpodUploadedMrPendingReq} 
                destinationPage={"/pod-upload-but-mr-pending"}
                color="#F9E6C2"
                initialQueryParams={filters}
              />
            </Card>
          </div>
          <div style={{ flex: '1 1 calc(50% - 12px)', boxSizing: 'border-box' }}>
            <Card body className="overflow-hidden" style={{minHeight: '300px'}}>
            <CardTitle onClick={handleCardTitleClick("/delivered-lrs-unbilled-report")} style={{ cursor:"pointer"}} className="h4">Delivered LRs Unbilled Report</CardTitle>
              <DrillDownTable 
                ref={el => tableRefs.current[1] = el}
                fetchDataFn={getDeliveredLrsUnbilledReportReq} 
                fetchDataAtBreadcrumbLimit={getdeliveredLrsUnbilledReportReq} 
                destinationPage={"/delivered-lrs-unbilled-report"}
                color="#E6D3F3"
                initialQueryParams={filters}
              />
            </Card>
          </div>
          <div style={{ flex: '1 1 calc(50% - 12px)', boxSizing: 'border-box' }}>
            <Card body className="overflow-hidden" style={{minHeight: '300px',}}>
            <CardTitle onClick={handleCardTitleClick("/total-unbilled-report")} style={{ cursor:"pointer"}} className="h4">Total Unbilled Report</CardTitle>
              <DrillDownTable 
                ref={el => tableRefs.current[4] = el}
                fetchDataFn={gettotalUnbilledHierarchicalReq} 
                fetchDataAtBreadcrumbLimit={gettotalUnbilledreportReq}
                destinationPage={"/total-unbilled-report"}
                color="#FFCCCB"
                initialQueryParams={filters}
              />
            </Card>
          </div> */}
      </div>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(Booking);