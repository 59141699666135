import React , {useEffect,useState,useRef} from "react"
import DrillDownTable from "components/CustomComponents/DrillDownTable";
import { connect } from "react-redux";
import { getcustomerAgingZoneReq, getagingPartyWiseReq } from "./CreditControlService";
import { Card } from "reactstrap";
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import { useLocation } from 'react-router-dom';

const BranchDetails = (props) => {

  document.title = "Aurum | Location Wise Outstanding";
  const location = useLocation();
  const breadcrumbs = location.state?.breadcrumbsState || [{ label: 'Home', field: '' }];
  const tableRef = useRef(null);

  const breadcrumbItems = [
    { title: "Home", link: "/dashboard" },
    { title: "Credit", link: "/credit-control" },
    { title: "Location Wise Outstanding", link: "#" }
  ]

  useEffect(() => {
    props.setBreadcrumbItems('Location Wise Outstanding' , breadcrumbItems)
  },)


  return (
    <React.Fragment>
        <Card body id="pdf-container" className="overflow-hidden">
        <DrillDownTable 
          ref={tableRef}
          fetchDataFn={getcustomerAgingZoneReq} 
          fetchDataAtBreadcrumbLimit={getagingPartyWiseReq}
          initialBreadcrumbs={breadcrumbs} 
        />
      </Card>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(BranchDetails);