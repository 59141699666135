import React , {useEffect,useState,useRef} from "react"
import { connect } from "react-redux";
import { Card } from "reactstrap";
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import { options } from "utils/pdfOptions";
import generatePDF from "react-to-pdf";
import { useLocation } from "react-router-dom";
import * as XLSX from 'xlsx';
import { getLHSPendingForVehichleArrivalHierarchicalReq, getLHSPendingForVehichleArrivalReq } from "./inwardService";
import DrillDownTableInward from "components/CustomComponents/DrillDownTableInward";

const VehicleArrival = (props) => {

  document.title = "Aurum | Vehicle Arrival";
  const location = useLocation();
  const breadcrumbs = location.state?.breadcrumbsState || [{ label: 'Home', field: '' }];
  const color = location.state?.color || "#E6D3F3";
  const queryParams = location.state?.queryParams || {};
  const tableRef = useRef(null);
  const [filters, setFilters] = useState({ ...queryParams });

  const breadcrumbItems = [
    { title: "Home", link: "/dashboard" },
    { title: "Booking", link: "/inward" },
    { title: "LHS Pending For Vehicle Arrival", link: "#" }
  ]

  useEffect(() => {
    props.setBreadcrumbItems('LHS Pending For Vehicle Arrival' , breadcrumbItems)
  },)

  const getTargetElement = () => document.getElementById("pdf-container");

  const downloadPDF = () => generatePDF(getTargetElement, options);
  
  console.debug(tableRef)
  const downloadExcel = () => {
    const wb = XLSX.utils.book_new();

    if (!tableRef.current || tableRef.current.rows.length === 0) {
      console.error('No table reference available to export.');
      return;
    }

    const ws = XLSX.utils.table_to_sheet(tableRef.current);
    XLSX.utils.book_append_sheet(wb, ws, "LHS Pending For Vehicle Arrival"); // Use document title for the sheet name

    if (wb.SheetNames.length === 0) {
      console.error('No data to export; workbook is empty.');
      return;
    }

    XLSX.writeFile(wb, "LHS Pending For Vehicle Arrival.xlsx"); // Use document title for the file name
  };

  const applyFilters = (filters) => {
    setFilters(filters || {});
  };

  return (
    <React.Fragment>
        <Card body id="pdf-container" className="overflow-hidden">
        <DrillDownTableInward 
          ref={tableRef}
          initialBreadcrumbs={breadcrumbs} 
          fetchDataFn={getLHSPendingForVehichleArrivalHierarchicalReq} 
          fetchDataAtBreadcrumbLimit={getLHSPendingForVehichleArrivalReq}  
          initialQueryParams={filters}
        />
      </Card>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(VehicleArrival);