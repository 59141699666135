import { 
    getLHSPendingForVehichleArrival,
    getLHSPendingForVehichleArrivalHierarchical,
    getLHSPendingForVehichleUnloading,
    getLHSPendingForVehichleUnloadingHierarchical,
} from "api";

export const getLHSPendingForVehichleArrivalHierarchicalReq = async (body) => {
    const response = await getLHSPendingForVehichleArrivalHierarchical(body);
    return response;
};
export const getLHSPendingForVehichleArrivalReq = async (body) => {
    const response = await getLHSPendingForVehichleArrival(body);
    return response;
};
export const getLHSPendingForVehichleUnloadingReq = async (body) => {
    const response = await getLHSPendingForVehichleUnloading(body);
    return response;
};
export const getLHSPendingForVehichleUnloadingHierarchicalReq = async (body) => {
    const response = await getLHSPendingForVehichleUnloadingHierarchical(body);
    return response;
};
   