import { 
    getcustomerExposureAnalysis,
    getcustomerAging,
    getagingPartyWise,
    getPaymentTimeAanalysis,
    getcustomerAgingZone,
    getCreditTimeAanalysis,
    getcustomerAgingBar,
    getcustomerAgingAmountDifference,
    getcustomerOutstandingSummary,
    getcustomerOutstandingFilter
} from "api";

export const getcustomerExposureAnalysisReq = async (body) => {
    const response = await getcustomerExposureAnalysis(body);
    return response;
};

export const getcustomerAgingBarReq = async (body) => {
    const response = await getcustomerAgingBar(body);
    return response;
};

export const getcustomerAgingZoneReq = async (body) => {
    const response = await getcustomerAgingZone(body);
    return response;
};

export const getagingPartyWiseReq = async (body) => {
    const response = await getagingPartyWise(body);
    return response;
};

export const getPaymentTimeAanalysisReq = async (body) => {
    const response = await getPaymentTimeAanalysis(body);
    return response;
};

export const getCreditTimeAanalysisReq = async (body) => {
    const response = await getCreditTimeAanalysis(body);
    return response;
};

export const getcustomerAgingReq = async (body) => {
    const response = await getcustomerAging(body);
    return response?.result;
};

export const getcustomerAgingAmountDifferenceReq = async (body) => {
    const response = await getcustomerAgingAmountDifference(body);
    return response?.result;
};

export const getcustomerOutstandingSummaryReq = async (body) => {
    const response = await getcustomerOutstandingSummary(body);
    return response;
};

export const getcustomerOutstandingFilterReq = async (body) => {
    const response = await getcustomerOutstandingFilter(body);
    return response;
};