import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import '../../components/CommonForBoth/rightbar.scss';
import { Link } from "react-router-dom";
import { getbillingCommonFilterReq } from './billingService';

const BillingFilterBar = ({ onApplyFilters,filters }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({
    load_type: filters?.load_type || '',
    billing_location: filters?.billing_location || '',
    payment_type: filters?.payment_type || '',
    customer_name: filters?.customer_name || '',
    marketer: filters?.marketer || ''
  });

  const [bookingTypes, setBookingTypes] = useState([]);
  const [billingLocations, setBillingLocations] = useState([]);
  const [paymentTypes, setPaymentTypes] = useState([]);

  const clearFilters = () => {
    setSelectedFilters({
      load_type: '',
      billing_location: '',
      payment_type: '',
      customer_name: '',
      marketer: ''
    });
    onApplyFilters({});
    setIsOpen(false);
  };

  const toggleFilterBar = () => {
    setIsOpen(!isOpen);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setSelectedFilters(prevState => ({
      ...prevState,
      [name]: value || '',
    }));
  };

  const applyFilters = () => {
    const filteredFilters = Object.fromEntries(
      Object.entries(selectedFilters).filter(([_, value]) => value !== '')
    );
    onApplyFilters(filteredFilters);
    setIsOpen(false);
  };

  useEffect(() => {
    const fetchFilters = async () => {
      try {
        const response = await getbillingCommonFilterReq();
        setBookingTypes(response?.booking_types || []);
        setBillingLocations(response?.billing_branches || []);
        setPaymentTypes(response?.payment_types || []);
      } catch (error) {
        console.error('Error fetching filters:', error);
      }
    };

    fetchFilters();
  }, []);

  return (
    <div>
      {!isOpen && (
        <button
          className="btn btn-primary rounded-circle position-fixed"
          style={{ bottom: '25px', right: '20px', zIndex: 9999, width: '60px', height: '60px', fontSize: '24px' }}
          onClick={toggleFilterBar}
        >
          <i className="mdi mdi-filter-variant" />
        </button>
      )}
      <div
        className={`offcanvas offcanvas-end filter-sidebar ${isOpen ? 'show' : ''}`}
        style={{ visibility: isOpen ? 'visible' : 'hidden', width: isOpen ? '300px' : '0px', transition: 'width 0.3s ease', zIndex: 9999 }}
      >
        <div>
          <SimpleBar style={{ height: '100vh' }}>
            <div data-simplebar className="h-100">
              <div className="rightbar-title px-3 py-4">
                <Link
                  to="#"
                  onClick={e => {
                    e.preventDefault();
                    toggleFilterBar();
                  }}
                  className="right-bar-toggle float-end"
                >
                  <i className="mdi mdi-close noti-icon" />
                </Link>
                <h5 className="offcanvas-title">Filters</h5>
              </div>
              <hr className="my-0" />
              <div className="offcanvas-body">
                {/* Load Type Filter */}
                <div className="mb-3">
                  <label className="mb-2 d-block">Load Type</label>
                  <select
                    className="form-select"
                    name="load_type"
                    onChange={handleFilterChange}
                    value={selectedFilters.load_type || ''}
                  >
                    <option value="">Select Load Type</option>
                    {bookingTypes.map((type) => (
                      <option key={type} value={type}>{type}</option>
                    ))}
                  </select>
                </div>

                {/* Billing Location Filter */}
                <div className="mb-3">
                  <label className="mb-2 d-block">Billing Location</label>
                  <select
                    className="form-select"
                    name="billing_location"
                    onChange={handleFilterChange}
                    value={selectedFilters.billing_location || ''}
                  >
                    <option value="">Select Billing Location</option>
                    {billingLocations.map((location) => (
                      <option key={location} value={location}>{location}</option>
                    ))}
                  </select>
                </div>

                {/* Payment Type Filter */}
                <div className="mb-3">
                  <label className="mb-2 d-block">Payment Type</label>
                  <select
                    className="form-select"
                    name="payment_type"
                    onChange={handleFilterChange}
                    value={selectedFilters.payment_type || ''}
                  >
                    <option value="">Select Payment Type</option>
                    {paymentTypes.map((type) => (
                      <option key={type} value={type}>{type}</option>
                    ))}
                  </select>
                </div>

                {/* Customer Name Filter */}
                <div className="mb-3">
                  <label className="mb-2 d-block">Customer Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="customer_name"
                    onChange={handleFilterChange}
                    value={selectedFilters.customer_name || ''}
                    placeholder="Enter Customer Name"
                  />
                </div>

                {/* Marketer Filter */}
                <div className="mb-3">
                  <label className="mb-2 d-block">Marketer</label>
                  <input
                    type="text"
                    className="form-control"
                    name="marketer"
                    onChange={handleFilterChange}
                    value={selectedFilters.marketer || ''}
                    placeholder="Enter Marketer Name"
                  />
                </div>

                <div className="d-flex justify-content-around">
                  <button className="btn btn-secondary" onClick={clearFilters}>
                    Clear All Filters
                  </button>
                  <button className="btn btn-primary" onClick={applyFilters}>
                    Apply Filters
                  </button>
                </div>
              </div>
            </div>
          </SimpleBar>
        </div>
      </div>
      {isOpen && (
        <div
          className="rightbar-overlay"
          onClick={toggleFilterBar}
        />
      )}
    </div>
  );
};

BillingFilterBar.propTypes = {
  onApplyFilters: PropTypes.func.isRequired,
};

export default BillingFilterBar;
