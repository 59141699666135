import React, { useState, useEffect, forwardRef } from 'react';
import Spinners from 'components/Common/Spinner';
import EnhancedTable from './EnhancedTable'; // Add the EnhancedTable component
import { 
  formatNumber,
  capitalizeWords,
  calculateTotalRow,
  createQueryParamsFromBreadcrumbs,darkenColor } from 'utils';
  import { useNavigate } from 'react-router-dom';
import { columnLabelDict } from 'pages/Billing/Constants/contants';

const DrillDownTable = forwardRef(({ fetchDataFn, fetchDataAtBreadcrumbLimit,destinationPage,initialQueryParams, initialBreadcrumbs , color}, ref) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  const [queryParams, setQueryParams] = useState([]);
  const [breadcrumbs, setBreadcrumbs] = useState(initialBreadcrumbs || [{ label: 'Home', field: '' }]);
  const [useEnhancedTable, setUseEnhancedTable] = useState(false);
  const defaultColor = '#dcdcdc'; // Light gray for borders and background
  const lighterDefaultColor = '#f9f9f9'; // Lighter background for rows
  const headerDefaultColor = '#e0e0e0'; // Gray for header
  
// Use provided color or default grayscale with checks
const backgroundColor = lighterDefaultColor;
const borderColor = color ? darkenColor(color, 0.2) : defaultColor;
const headerColor = color ? color : headerDefaultColor;
const [loadingParams, setLoadingParams] = useState(true); // Track if queryParams are being set
  
    // Initialize the navigate function
    const navigate = useNavigate();


  // Consolidate queryParams setup in one useEffect
  useEffect(() => {
    let params = {};
    
    // Process breadcrumbs into query params
    if (initialBreadcrumbs && initialBreadcrumbs.length > 0) {
      params = initialBreadcrumbs
        .filter(crumb => crumb.label !== 'Home')
        .reduce((acc, crumb) => {
          acc[crumb.field] = crumb.label;
          return acc;
        }, {});
    }

    // Merge initialQueryParams if they exist
    if (initialQueryParams) {
      params = {
        ...initialQueryParams,
        ...params
      };
    }

    // Only set queryParams and update loading state after both are processed
    setQueryParams(params);
    setLoadingParams(false); // Params are ready now, can proceed to fetch data
  }, [initialBreadcrumbs, initialQueryParams]);

  // Fetch data only when queryParams and breadcrumbs are set and loadingParams is false
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        let response;
        let responseData;
        const combinedParams = {
          ...queryParams,
          ...(initialQueryParams || {}),
        };

        if (breadcrumbs.length === 5) {
          setUseEnhancedTable(true);
          response = await fetchDataAtBreadcrumbLimit(combinedParams);
          responseData = response?.results || [];
        } else {
          setUseEnhancedTable(false);
          if(fetchDataFn){
            response = await fetchDataFn(combinedParams);
            responseData = response?.summary || [];
          }
        }

        setData(responseData);

        if (responseData.length > 0) {
          const firstRow = responseData[0];
          const columnKeys = Object.keys(firstRow);
          setColumns(columnKeys);
        } else {
          setColumns([]);
        }

      } catch (error) {
        console.error('Error fetching data:', error);
        setData([]);
      } finally {
        setLoading(false);
      }
    };

    // Ensure the API call is only triggered when both queryParams and breadcrumbs are ready
    if (!loadingParams && (Object.keys(queryParams).length > 0 || breadcrumbs.length > 0)) {
      fetchData();
    }
  }, [fetchDataFn, fetchDataAtBreadcrumbLimit, breadcrumbs, queryParams, loadingParams]);

    
  const handleRowClick = (field, value) => {
    // Check if the current page is /billing
    const newParams = {
      ...queryParams,
      ...(field && { [field]: value }),
    };
    setQueryParams(newParams);
    
    setBreadcrumbs((prev) => [
      ...prev,
      { label: value, field },
    ]);
    
    const isBillingPage = window.location.pathname === "/billing" || "/inward";

    if (breadcrumbs.length >= 4 && isBillingPage) {
      // After reaching breadcrumb limit, navigate to the appropriate page
      const breadcrumbsState = [
        ...breadcrumbs,
        { label: value, field }
      ];

      if (destinationPage) {
        navigate(destinationPage, { state: { breadcrumbsState,color,queryParams } });
      }
    }
  };

  const handleBreadcrumbClick = (index) => {
    const newBreadcrumbs = breadcrumbs.slice(0, index + 1);
    const newParams = createQueryParamsFromBreadcrumbs(newBreadcrumbs);
  
    setQueryParams({
      ...initialQueryParams,
      ...newParams,
    });
    setBreadcrumbs(newBreadcrumbs);
  };

  const totalRow = calculateTotalRow(data, columns);

  return (
    <div >
      {/* Breadcrumbs */}
      {data?.length === 0 ? (
         null
        ) : (<nav style={{ marginBottom: '16px' }}>
        <div style={{ display: 'flex', alignItems: 'center', color: '#4a4a4a', fontSize: '10px' }}>
          {breadcrumbs.map((crumb, index) => (
            <React.Fragment key={index}>
              {index > 0 && <span style={{ color: '#9e9e9e', margin: '0 8px' }}>/</span>}
              {index === breadcrumbs.length - 1 ? (
                <span style={{ fontWeight: 'bold' }}>{crumb.label}</span>
              ) : (
                <span
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() => handleBreadcrumbClick(index)}
                >
                  {crumb.label}
                </span>
              )}
            </React.Fragment>
          ))}
        </div>
      </nav>)}

      {/* Conditional Rendering for Different Tables */}
      {loading ? (
        <div style={{ position: 'relative' }}>
          <Spinners setLoading={setLoading} />
        </div>
      ) : useEnhancedTable ? (
        <EnhancedTable data={data} columns={columns}/>
      ) : (
        data?.length === 0 ? (
          <p style={{ textAlign: 'center', color: 'green' }}>Good Work, All Task Are Completed.</p>
        ) : (
          <div style={{ overflowX: 'auto' }}>
         <table ref={ref} style={{ minWidth: '100%', backgroundColor: 'white', borderCollapse: 'collapse', border: `1px solid ${borderColor}`, fontSize: '12px' }}>
            <thead style={{ backgroundColor: headerColor }}>
              <tr>
                {columns?.map((col, index) => (
                  <th key={index} style={{ borderBottom: `2px solid ${borderColor}`, padding: '12px', textAlign: 'left', backgroundColor: headerColor }}>
                    {columnLabelDict[col] || capitalizeWords(col.replace(/_/g, ' '))}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data?.map((row, rowIndex) => (
                <tr
                  key={rowIndex}
                  onClick={() => {
                    if (breadcrumbs.length <= 4) {
                      handleRowClick(Object.keys(row)[0].toLowerCase().replace(' ', '_'), row[Object.keys(row)[0]]);
                    }
                  }}
                  style={{
                    cursor: breadcrumbs.length >= 5 ? 'default' : 'pointer',
                    backgroundColor: backgroundColor,
                    '&:hover': { backgroundColor: borderColor }
                  }}
                >
                  {columns?.map((col, colIndex) => (
                    <td key={colIndex} style={{ borderBottom: `1px solid ${borderColor}`, padding: '12px' }}>
                      {col === columns[0]
                        ? row[col]
                        : typeof row[col] === 'number'
                          ? formatNumber(row[col])
                          : row[col]
                      }
                    </td>
                  ))}
                </tr>
              ))}
              <tr style={{ backgroundColor: headerColor }}>
                <td style={{ borderTop: `2px solid ${borderColor}`, padding: '12px', fontWeight: 'bold' }}>Total</td>
                {columns?.slice(1).map((col, index) => (
                  <td key={index} style={{ borderTop: `2px solid ${borderColor}`, padding: '12px', fontWeight: 'bold' }}>
                    {typeof totalRow[col] === 'number'
                      ? formatNumber(totalRow[col])
                      : totalRow[col]
                    }
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
          </div>
        )
      )}
    </div>
  );
});

export default DrillDownTable;
