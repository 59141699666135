import React from "react"
import { Navigate } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "pages/Authentication/Logout"
// Inner Authentication
import Register1 from "../pages/AuthenticationInner/Register"
import Recoverpw from "../pages/AuthenticationInner/Recoverpw"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

// Forms
import FormElements from "../pages/Forms/FormElements"
import FormAdvanced from "../pages/Forms/FormAdvanced"
import FormEditors from "../pages/Forms/FormEditors"
import FormValidations from "../pages/Forms/FormValidations"
import FormUpload from "../pages/Forms/FormUpload"
import FormXeditable from "../pages/Forms/FormXeditable"

//Ui
import UiAlert from "../pages/Ui/UiAlert"
import UiButtons from "../pages/Ui/UiButtons"
import UiBadge from "../pages/Ui/UiBadge"
import UiCards from "../pages/Ui/UiCards"
import UiCarousel from "../pages/Ui/UiCarousel"
import UiDropdown from "../pages/Ui/UiDropdown"
import UiGrid from "../pages/Ui/UiGrid"
import UiImages from "../pages/Ui/UiImages"
import UiLightbox from "../pages/Ui/UiLightbox"
import UiModal from "../pages/Ui/UiModal"
import UiPagination from "../pages/Ui/UiPagination"
import UiPopoverTooltips from "../pages/Ui/UiPopoverTooltips"
import UiProgressbar from "../pages/Ui/UiProgressbar"
import UiTabsAccordions from "../pages/Ui/UiTabsAccordions"
import UiTypography from "../pages/Ui/UiTypography"
import UiVideo from "../pages/Ui/UiVideo"
import UiSessionTimeout from "../pages/Ui/UiSessionTimeout"
import UiRangeSlider from "../pages/Ui/UiRangeSlider"

//Extra Pages
import Pages404 from "../pages/Extra Pages/pages-404";
import Pages500 from "../pages/Extra Pages/pages-500";
import UiUtilities from "pages/Ui/UiUtilities"
import UiColors from "pages/Ui/UiColors"
import UiOffcanvas from "pages/Ui/UiOffcanvas"

//totalUnbilledReport
import TotalUnbilledReport from "pages/Billing/TotalUnbilledReport"
import BillsPendingApproval from "pages/Billing/BillsPendingApproval"
import DeliveredLrsUnbilledReport from "pages/Billing/DeliveredLrsUnbilledReport"
import PoDUploadedButBillPending from "pages/Billing/PoDUploadedButBillPending"
import PoDUploadedButMRPending from "pages/Billing/PoDUploadedButMRPending"
import BillingTimeAnalysis from "pages/Billing/BillingTimeAnalysis"
import Billing from "pages/Billing/Billing"
import CreditControl from "pages/Credit-Control/CreditControl"
import CustomerReport from "pages/Credit-Control/BranchDetails"
import CustomerBillsPending from "pages/Credit-Control/CustomerBillsPending"
import Booking from "pages/Booking/Booking"
import LrPendingforDispatch from "pages/Booking/LrPendingforDispatch"
import DoorChallan from "pages/Booking/doorChallan"
import Inward from "pages/inward/Inward"
import VehicleArrival from "pages/inward/vehicleArrival"
import VehicleUnloading from "pages/inward/vehicleUnloading"

import { components } from "react-select"

const userRoutes = [
  { path: "/dashboard", component: <Dashboard /> },

   // // //profile
  { path: "/profile", component: <UserProfile /> },

  // // Forms
  { path: "/form-elements", component: <FormElements /> },
  { path: "/form-advanced", component: <FormAdvanced /> },
  { path: "/form-editors", component: <FormEditors /> },
  { path: "/form-uploads", component: <FormUpload /> },
  { path: "/form-validation", component: <FormValidations /> },
  { path: "/form-xeditable", component: <FormXeditable /> },

  // // Ui
  { path: "/ui-alerts", component: <UiAlert /> },
  { path: "/ui-buttons", component: <UiButtons /> },
  { path: "/ui-badge", component: <UiBadge /> },
  { path: "/ui-cards", component: <UiCards /> },
  { path: "/ui-carousel", component: <UiCarousel /> },
  { path: "/ui-dropdowns", component: <UiDropdown /> },
  { path: "/ui-grid", component: <UiGrid /> },
  { path: "/ui-images", component: <UiImages /> },
  { path: "/ui-lightbox", component: <UiLightbox /> },
  { path: "/ui-modals", component: <UiModal /> },
  { path: "/ui-pagination", component: <UiPagination /> },
  { path: "/ui-popover-tooltip", component: <UiPopoverTooltips /> },
  { path: "/ui-progressbars", component: <UiProgressbar /> },
  { path: "/ui-tabs-accordions", component: <UiTabsAccordions /> },
  { path: "/ui-typography", component: <UiTypography /> },
  { path: "/ui-video", component: <UiVideo /> },
  { path: "/ui-session-timeout", component: <UiSessionTimeout /> },
  { path: "/ui-rangeslider", component: <UiRangeSlider /> },
  { path: "/ui-utilities", component: <UiUtilities /> },
  { path: "/ui-colors", component: <UiColors /> },
  { path: "/ui-offcanvas", component: <UiOffcanvas /> },

  // //totalUnbilledReport
  { path: "/total-unbilled-report", component: <TotalUnbilledReport /> },
  { path: "/bills-pending-approval", component: <BillsPendingApproval/> },
  { path: "/delivered-lrs-unbilled-report", component: <DeliveredLrsUnbilledReport /> },
  { path: "/pod-uploaded-but-bill-pending", component: <PoDUploadedButBillPending /> },
  { path: "/pod-upload-but-mr-pending", component: <PoDUploadedButMRPending /> },
  { path: "/billing-time-analysis", component: <BillingTimeAnalysis /> },
  { path: "/billing", component: <Billing /> },

  // //Credit Control Report
  { path: "/credit-control", component: <CreditControl /> },
  { path: "/branch-details", component: <CustomerReport /> },
  { path: "/customer-bills", component: <CustomerBillsPending /> },

  //Booking Dashboard
  { path: "/booking", component: <Booking /> },
  { path: "/lrs-pending-dispatch", component: <LrPendingforDispatch /> },
  { path: "/door-challan", component: <DoorChallan /> },


  // Inward Dashbaord
  { path: "/inward", component: <Inward /> },
  { path: "/lhs-pending-vehicle-arrival", component: <VehicleArrival />},
  { path: "/lhs-pending-vehicle-unloading", component: <VehicleUnloading />},

  // this route should be at the end of all other routes
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
]

const authRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  // { path: "/forgot-password", component: <ForgetPwd /> },
  // { path: "/register", component: <Register /> },

  { path: "/pages-404", component: <Pages404 /> },
  { path: "/pages-500", component: <Pages500 /> },

  // Authentication Inner
  // { path: "/login", component: <Login1 /> },
  { path: "/register", component: <Register1 /> },
  { path: "/forgot-password", component: <Recoverpw /> },
  // { path: "/auth-lock-screen", component: <LockScreen /> },
]

export { userRoutes, authRoutes }