import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../../components/CommonForBoth/rightbar.scss';
import { Link } from "react-router-dom"
import { getcustomerOutstandingFilterReq } from './CreditControlService';

const CreditFilterBar = ({ onApplyFilters,props }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({
    collectionRegionalOffice: '',
    collectionAreaOffice: '',
    collectionBranchOffice: '',
    partyName: '',
    marketingExecutive: '',
    collectionExecutive: '',
    billDate: '',
    dueDate: '',
    billAmount: '',
    overdueDays: [],
  });
  const [searchOptions, setSearchOptions] = useState({
    partyName: [],
    marketingExecutive: [],
    collectionExecutive: [],
  });
  const clearFilters = () => {
    setSelectedFilters({
      collectionRegionalOffice: '',
      collectionAreaOffice: '',
      collectionBranchOffice: '',
      partyName: '',
      marketingExecutive: '',
      collectionExecutive: '',
      billDate: '',
      dueDate: '',
      billAmount: '',
      overdueDays: [] // Assuming overdueDays is a checkbox array
    });
    setIsOpen(false);
  };

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const response = await [];
        
        setSearchOptions({
          partyName: response?.party_names || [],
          marketingExecutive: response?.marketing_executives || [],
          collectionExecutive: response?.collection_executives || [],
          collectionBranchOffice: response?.collection_branches || [],
          collectionRegionalOffice: response?.collection_regional_offices || [],
          collectionAreaOffice: response?.collection_area_offices || [],
        });
      } catch (error) {
        console.error('Error fetching filter options:', error);
      }
    };

    fetchOptions();
  }, []);


  const toggleFilterBar = () => {
    setIsOpen(!isOpen);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setSelectedFilters(prevState => ({
      ...prevState,
      [name]: value || '', // Handle empty values
    }));
  };

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    setSelectedFilters((prevState) => {
      const overdueDays = checked
        ? [...prevState.overdueDays, value] // Add to array if checked
        : prevState.overdueDays.filter((day) => day !== value); // Remove if unchecked

      return {
        ...prevState,
        overdueDays
      };
    });
  };

  const handleDateChange = (name, date) => {
    setSelectedFilters(prevState => ({
      ...prevState,
      [name]: date ? date.toISOString().split('T')[0] : '', // Handle null values
    }));
  };

  const applyFilters = () => {
    // Filter out keys with null, empty strings, or empty arrays
    const filteredFilters = Object.fromEntries(
      Object.entries(selectedFilters).filter(([_, value]) =>
        value !== '' && (Array.isArray(value) ? value.length > 0 : value !== '')
      )
    );
    onApplyFilters(filteredFilters);
    setIsOpen(false);
  };
  

  return (
    <div>
      {!isOpen && (
        <button
          className="btn btn-primary rounded-circle position-fixed"
          style={{ bottom: '25px', right: '20px', zIndex: 9999, width: '60px', height: '60px', fontSize: '24px' }}
          onClick={toggleFilterBar}
        >
          <i className="mdi mdi-filter-variant" />
        </button>
      )}
      <div
        className={`offcanvas offcanvas-end filter-sidebar ${isOpen ? 'show' : ''}`}
        style={{ visibility: isOpen ? 'visible' : 'hidden', 
          width: isOpen ? '300px' : '0px', // Adjust width here
          transition: 'width 0.3s ease',
             zIndex: 9999 }}
      >
        <div>
      <div>
          <SimpleBar style={{ height: '100vh' }}>
          <div data-simplebar className="h-100">
            <div className="rightbar-title px-3 py-4">
              <Link
                to="#"
                onClick={e => {
                  e.preventDefault();
                  toggleFilterBar();
                }}
                className="right-bar-toggle float-end"
              >
                <i className="mdi mdi-close noti-icon" />
              </Link>
              <h5 className="offcanvas-title">Filters</h5>
            </div>
            <hr className="my-0" />
            <div className="offcanvas-body">
              {/* Collection Regional Office */}
              <div className="mb-3">
                <label className="mb-2 d-block">Collection Regional Office</label>
                <select
                  className="form-select"
                  name="collectionRegionalOffice"
                  onChange={handleFilterChange}
                  value={selectedFilters.collectionRegionalOffice || ''}
                >
                  <option value="">Select Collection Regional Office</option>
                  {/* Add options here */}
                </select>
              </div>

              {/* Collection Area Office */}
              <div className="mb-3">
                <label className="mb-2 d-block">Collection Area Office</label>
                <select
                  className="form-select"
                  name="collectionAreaOffice"
                  onChange={handleFilterChange}
                  value={selectedFilters.collectionAreaOffice || ''}
                >
                  <option value="">Select Collection Area Office</option>
                  {/* Add options here */}
                </select>
              </div>

              {/* Collection Branch Office */}
              <div className="mb-3">
                <label className="mb-2 d-block">Collection Branch Office</label>
                <select
                  className="form-select"
                  name="collectionBranchOffice"
                  onChange={handleFilterChange}
                  value={selectedFilters.collectionBranchOffice || ''}
                >
                  <option value="">Select Collection Branch Office</option>
                  {/* Add options here */}
                </select>
              </div>

              {/* Party Name */}
              <div className="mb-3">
                <label className="mb-2 d-block">Party Name</label>
                <select
                  className="form-select"
                  name="partyName"
                  onChange={handleFilterChange}
                  value={selectedFilters.partyName || ''}
                >
                  <option value="">Select Party Name</option>
                  {searchOptions.partyName.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>

              {/* Marketing Executive */}
              <div className="mb-3">
                <label className="mb-2 d-block">Marketing Executive</label>
                <select
                  className="form-select"
                  name="marketingExecutive"
                  onChange={handleFilterChange}
                  value={selectedFilters.marketingExecutive || ''}
                >
                  <option value="">Select Marketing Executive</option>
                  {searchOptions.marketingExecutive.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>

              {/* Collection Executive */}
              <div className="mb-3">
                <label className="mb-2 d-block">Collection Executive</label>
                <select
                  className="form-select"
                  name="collectionExecutive"
                  onChange={handleFilterChange}
                  value={selectedFilters.collectionExecutive || ''}
                >
                  <option value="">Select Collection Executive</option>
                  {searchOptions.collectionExecutive.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>

              {/* Bill Date */}
              <div className="mb-3">
                <label className="mb-2 d-block">Bill Date</label>
                <DatePicker
                  selected={selectedFilters.billDate ? new Date(selectedFilters.billDate) : null}
                  onChange={date => handleDateChange('billDate', date)}
                  className="form-control"
                  placeholderText="Select Bill Date"
                />
              </div>

              {/* Due Date */}
              <div className="mb-3">
                <label className="mb-2 d-block">Due Date</label>
                <DatePicker
                  selected={selectedFilters.dueDate ? new Date(selectedFilters.dueDate) : null}
                  onChange={date => handleDateChange('dueDate', date)}
                  className="form-control"
                  placeholderText="Select Due Date"
                />
              </div>

              {/* Bill Amount */}
              <div className="mb-3">
                <label className="mb-2 d-block">Bill Amount</label>
                <select
                  className="form-select"
                  name="billAmount"
                  onChange={handleFilterChange}
                  value={selectedFilters.billAmount || ''}
                >
                  <option value="">Select Bill Amount</option>
                  {/* Add options here */}
                </select>
              </div>

              {/* Overdue Days */}
              <div className="mb-3">
                <label className="mb-2 d-block">Overdue Days</label>
                  <div className="d-flex flex-wrap">
                    {['0-30', '31-60', '61-90', '91-180', '180+'].map(range => (
                      <div key={range} className="form-check me-3">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="overdueDays"
                          value={range}
                          checked={selectedFilters.overdueDays.includes(range)}
                          onChange={handleCheckboxChange}
                        />
                        <label className="form-check-label">{range}</label>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="d-flex justify-content-around">
                  <button className="btn btn-secondary" onClick={clearFilters}>
                    Clear All Filters
                  </button>
                  <button className="btn btn-primary" onClick={applyFilters}>
                    Apply Filters
                  </button>
                </div>
            </div>
            </div>
          </SimpleBar>
          </div>
        </div>
      </div>
      {isOpen && (
        <div
          className="rightbar-overlay"
          onClick={toggleFilterBar}
        />
      )}
    </div>
  );
};

CreditFilterBar.propTypes = {
  onApplyFilters: PropTypes.func.isRequired,
};

export default CreditFilterBar;