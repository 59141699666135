import axios from "axios";

const baseUrl = process.env.API_BASE_URL || "https://aurum.d10xpoc.xyz/api";

const API_URL = {
  //Authentication Apis
  login: "/iam/login/",
  logout: "/iam/logout/",
  
  //Billing 
  freightBillsHierarchical: "/billing/freight-bills-aggregate/",
  totalUnbilledHierarchical: "/billing/total-unbilled-hierarchical/",
  PoDUploadedButMRPending: "/billing/pod-uploaded-mr-pending-hierarchical/",
  PoDUploadedButBillPending: "/billing/pod-uploaded-received-bill-pending-hierarchical/",
  BillsPendingApproval: "/billing/bills-pending-approval-hierarchical/",
  DeliveredLrsUnbilledReport: "/billing/delivered-lrs-unbilled-hierarchical/",
  podUploadedMrPending : "/billing/pod-uploaded-mr-pending/",
  podUploadedReceivedBillPending : "/billing/pod-uploaded-received-bill-pending/",
  freightBills: "/billing/freight-bills/",
  totalUnbilledreport : "/billing/total-unbilled-report/",
  deliveredLrsUnbilledReport: "/billing/delivered-lrs-unbilled-report/",
  billsPendingApproval: "/billing/bills-pending-approval/",

  //Customer Outstanding
  customerExposureAnalysis: "/credit/customer-exposure-analysis/",
  customerAgingBar: "/credit/top-20-parties/",
  customerAgingZone: "/credit/customer-aging-zone-wise/",
  agingPartyWise: "/credit/customer-aging-party-wise/",
  paymentTimeAnalysis: "/credit/payment-time-analysis/",
  creditTimeAnalysis: "/credit/credit-time-analysis/",
  customerAging: "/credit/all-parties/",
  customerAgingAmountDifference: "/credit/customer-aging-amount-difference/",

  // Booking Dashboard
  lrPendingForDispatchHierarchical: "/billing/lr-pending-for-dispatch-hierarchical/",
  lrPendingDispatch: "/billing/lr-pending-dispatch/",
  lrPendingForDoorChallenHierarchical: "/billing/lr-pending-for-door-challen-hierarchical/",
  lrPending: "/billing/lr-pending/",
  

  // Inward Dashboard 
  LHSPendingForVehichleArrivalHierarchical: "/inward/lhs-pending-vehicle-arrivals-aggregate/",
  LHSPendingForVehichleArrival: "/inward/lhs-pending-vehicle-arrivals/",
  LHSPendingForVehichleUnloadingHierarchical: "/inward/lhs-pending-vehicle-unloading-aggregate/",
  LHSPendingForVehichleUnloading: "/inward/lhs-pending-vehicle-unloading/",

  //Summary 
  customerOutstandingSummary: "/credit/customer-aging-stats/",
  billingSummary: "/billing/billing-summary/",
  bookingSummary: "/billing/booking-lr-count/",

  // Filters
  customerOutstandingFilter: "/credit/customer-aging-filters/",
  billingCommonFilter: "/billing/billing-common-filter/",
  bookingCommonFilter: "",
};

export async function login(body) {
  return new Promise((resolve) => {
    axios
      .post(`${baseUrl}${API_URL.login}`, body)
      .then((res) => {
        resolve(res.data);
        return res.data;
      })
      .catch((error) => {
        console.log(error);
        resolve(error.response.data);
      });
  });
}

export async function logout(body) {
    return new Promise((resolve) => {
      axios
        .post(`${baseUrl}${API_URL.logout}`, body,{ 
            withCredentials : true })
        .then((res) => {
          resolve(res.data);
          return res.data;
        })
        .catch((error) => {
          console.log(error);
          resolve(error.response.data);
        });
    });
  }
  
export async function getfreightBillsHierarchical(queryParams) {
return new Promise((resolve, reject) => {
    axios
    .get(`${baseUrl}${API_URL.freightBillsHierarchical}`, { 
        params: queryParams , withCredentials : true })
    .then((res) => {
        resolve(res.data);
    })
    .catch((error) => {
        reject(error.response.status);
    });
});
}

export async function gettotalUnbilledHierarchical(queryParams) {
    return new Promise((resolve, reject) => {
        axios
        .get(`${baseUrl}${API_URL.totalUnbilledHierarchical}`, { 
            params: queryParams , withCredentials : true })
        .then((res) => {
            resolve(res.data);
        })
        .catch((error) => {
            reject(error.response.status);
        });
    });
}

export async function getPoDUploadedButMRPending(queryParams) {
    return new Promise((resolve, reject) => {
        axios
        .get(`${baseUrl}${API_URL.PoDUploadedButMRPending}`, { 
            params: queryParams , withCredentials : true })
        .then((res) => {
            resolve(res.data);
        })
        .catch((error) => {
            reject(error.response.status);
        });
    });
}

export async function getPoDUploadedButBillPending(queryParams) {
    return new Promise((resolve, reject) => {
        axios
        .get(`${baseUrl}${API_URL.PoDUploadedButBillPending}`, { 
            params: queryParams , withCredentials : true })
        .then((res) => {
            resolve(res.data);
        })
        .catch((error) => {
            reject(error.response.status);
        });
    });
}

export async function getBillsPendingApproval(queryParams) {
    return new Promise((resolve, reject) => {
        axios
        .get(`${baseUrl}${API_URL.billsPendingApproval}`, { 
            params: queryParams , withCredentials : true })
        .then((res) => {
            resolve(res.data);
        })
        .catch((error) => {
            reject(error.response.status);
        });
    });
}

export async function getDeliveredLrsUnbilledReport(queryParams) {
    return new Promise((resolve, reject) => {
        axios
        .get(`${baseUrl}${API_URL.DeliveredLrsUnbilledReport}`, { 
            params: queryParams , withCredentials : true })
        .then((res) => {
            resolve(res.data);
        })
        .catch((error) => {
            reject(error.response.status);
        });
    });
}

export async function getpodUploadedMrPending(queryParams) {
    return new Promise((resolve, reject) => {
        axios
        .get(`${baseUrl}${API_URL.podUploadedMrPending}`, { 
            params: queryParams , withCredentials : true })
        .then((res) => {
            resolve(res.data);
        })
        .catch((error) => {
            reject(error.response.status);
        });
    });
}

export async function getpodUploadedReceivedBillPending(queryParams) {
    return new Promise((resolve, reject) => {
        axios
        .get(`${baseUrl}${API_URL.podUploadedReceivedBillPending}`, { 
            params: queryParams , withCredentials : true })
        .then((res) => {
            resolve(res.data);
        })
        .catch((error) => {
            reject(error.response.status);
        });
    });
}

export async function getfreightBills(queryParams) {
    return new Promise((resolve, reject) => {
        axios
        .get(`${baseUrl}${API_URL.freightBills}`, { 
            params: queryParams , withCredentials : true })
        .then((res) => {
            resolve(res.data);
        })
        .catch((error) => {
            reject(error.response.status);
        });
    });
}

export async function gettotalUnbilledreport(queryParams) {
    return new Promise((resolve, reject) => {
        axios
        .get(`${baseUrl}${API_URL.totalUnbilledreport}`, { 
            params: queryParams , withCredentials : true })
        .then((res) => {
            resolve(res.data);
        })
        .catch((error) => {
            reject(error.response.status);
        });
    });
}

export async function getdeliveredLrsUnbilledReport(queryParams) {
    return new Promise((resolve, reject) => {
        axios
        .get(`${baseUrl}${API_URL.deliveredLrsUnbilledReport}`, { 
            params: queryParams , withCredentials : true })
        .then((res) => {
            resolve(res.data);
        })
        .catch((error) => {
            reject(error.response.status);
        });
    });
}

export async function getbillsPendingApproval(queryParams) {
    return new Promise((resolve, reject) => {
        axios
        .get(`${baseUrl}${API_URL.billsPendingApproval}`, { 
            params: queryParams , withCredentials : true })
        .then((res) => {
            resolve(res.data);
        })
        .catch((error) => {
            reject(error.response.status);
        });
    });
}

export async function getcustomerExposureAnalysis(queryParams) {
    return new Promise((resolve, reject) => {
        axios
        .get(`${baseUrl}${API_URL.customerExposureAnalysis}`, { 
            params: queryParams , withCredentials : true })
        .then((res) => {
            resolve(res.data);
        })
        .catch((error) => {
            reject(error.response.status);
        });
    });
}

export async function getcustomerAgingBar(queryParams) {
    return new Promise((resolve, reject) => {
        axios
        .get(`${baseUrl}${API_URL.customerAgingBar}`, { 
            params: queryParams , withCredentials : true })
        .then((res) => {
            resolve(res.data);
        })
        .catch((error) => {
            reject(error.response.status);
        });
    });
}

export async function getcustomerAgingZone(queryParams) {
    return new Promise((resolve, reject) => {
        axios
        .get(`${baseUrl}${API_URL.customerAgingZone}`, { 
            params: queryParams , withCredentials : true })
        .then((res) => {
            resolve(res.data);
        })
        .catch((error) => {
            reject(error.response.status);
        });
    });
}

export async function getagingPartyWise(queryParams) {
    return new Promise((resolve, reject) => {
        axios
        .get(`${baseUrl}${API_URL.agingPartyWise}`, { 
            params: queryParams , withCredentials : true })
        .then((res) => {
            resolve(res.data);
        })
        .catch((error) => {
            reject(error.response.status);
        });
    });
}

export async function getPaymentTimeAanalysis(queryParams) {
    return new Promise((resolve, reject) => {
        axios
        .get(`${baseUrl}${API_URL.paymentTimeAnalysis}`, { 
            params: queryParams , withCredentials : true })
        .then((res) => {
            resolve(res.data);
        })
        .catch((error) => {
            reject(error.response.status);
        });
    });
}

export async function getCreditTimeAanalysis(queryParams) {
    return new Promise((resolve, reject) => {
        axios
        .get(`${baseUrl}${API_URL.creditTimeAnalysis}`, { 
            params: queryParams , withCredentials : true })
        .then((res) => {
            resolve(res.data);
        })
        .catch((error) => {
            reject(error.response.status);
        });
    });
}

export async function getcustomerAging(queryParams) {
    return new Promise((resolve, reject) => {
        axios
        .get(`${baseUrl}${API_URL.customerAging}`, { 
            params: queryParams , withCredentials : true })
        .then((res) => {
            resolve(res.data);
        })
        .catch((error) => {
            reject(error.response.status);
        });
    });
}

export async function getcustomerAgingAmountDifference(queryParams) {
    return new Promise((resolve, reject) => {
        axios
        .get(`${baseUrl}${API_URL.customerAgingAmountDifference}`, { 
            params: queryParams , withCredentials : true })
        .then((res) => {
            resolve(res.data);
        })
        .catch((error) => {
            reject(error.response.status);
        });
    });
}

export async function getcustomerOutstandingSummary(queryParams) {
    return new Promise((resolve, reject) => {
        axios
        .get(`${baseUrl}${API_URL.customerOutstandingSummary}`, { 
            params: queryParams , withCredentials : true })
        .then((res) => {
            resolve(res.data);
        })
        .catch((error) => {
            reject(error.response.status);
        });
    });
}

export async function getcustomerOutstandingFilter(queryParams) {
    return new Promise((resolve, reject) => {
        axios
        .get(`${baseUrl}${API_URL.customerOutstandingFilter}`, { 
            params: queryParams , withCredentials : true })
        .then((res) => {
            resolve(res.data);
        })
        .catch((error) => {
            reject(error.response.status);
        });
    });
}

export async function getbillingCommonFilter(queryParams) {
    return new Promise((resolve, reject) => {
        axios
        .get(`${baseUrl}${API_URL.billingCommonFilter}`, { 
            params: queryParams , withCredentials : true })
        .then((res) => {
            resolve(res.data);
        })
        .catch((error) => {
            reject(error.response.status);
        });
    });
}

export async function getbillingSummary(queryParams) {
    return new Promise((resolve, reject) => {
        axios
        .get(`${baseUrl}${API_URL.billingSummary}`, { 
            params: queryParams , withCredentials : true })
        .then((res) => {
            resolve(res.data);
        })
        .catch((error) => {
            reject(error.response.status);
        });
    });
}

export async function getlrPendingDispatch(queryParams) {
    return new Promise((resolve, reject) => {
        axios
        .get(`${baseUrl}${API_URL.lrPendingDispatch}`, { 
            params: queryParams , withCredentials : true })
        .then((res) => {
            resolve(res.data);
        })
        .catch((error) => {
            reject(error.response.status);
        });
    });
}

export async function getlrPendingForDispatchHierarchical(queryParams) {
    return new Promise((resolve, reject) => {
        axios
        .get(`${baseUrl}${API_URL.lrPendingForDispatchHierarchical}`, { 
            params: queryParams , withCredentials : true })
        .then((res) => {
            resolve(res.data);
        })
        .catch((error) => {
            reject(error.response.status);
        });
    });
}

export async function getlrPendingForDoorChallenHierarchical(queryParams) {
    return new Promise((resolve, reject) => {
        axios
        .get(`${baseUrl}${API_URL.lrPendingForDoorChallenHierarchical}`, { 
            params: queryParams , withCredentials : true })
        .then((res) => {
            resolve(res.data);
        })
        .catch((error) => {
            reject(error.response.status);
        });
    });
}

export async function getlrPending(queryParams) {
    return new Promise((resolve, reject) => {
        axios
        .get(`${baseUrl}${API_URL.lrPending}`, { 
            params: queryParams , withCredentials : true })
        .then((res) => {
            resolve(res.data);
        })
        .catch((error) => {
            reject(error.response.status);
        });
    });
}

export async function getbookingSummary(queryParams) {
    return new Promise((resolve, reject) => {
        axios
        .get(`${baseUrl}${API_URL.bookingSummary}`, { 
            params: queryParams , withCredentials : true })
        .then((res) => {
            resolve(res.data);
        })
        .catch((error) => {
            reject(error.response.status);
        });
    });
}

export async function getbookingCommonFilter(queryParams) {
    return new Promise((resolve, reject) => {
        axios
        .get(`${baseUrl}${API_URL.bookingCommonFilter}`, { 
            params: queryParams , withCredentials : true })
        .then((res) => {
            resolve(res.data);
        })
        .catch((error) => {
            reject(error.response.status);
        });
    });
}

// Inward LHS Pending for vehicle arrival

export async function getLHSPendingForVehichleArrivalHierarchical(queryParams) {
    return new Promise((resolve, reject) => {
        axios
        .get(`${baseUrl}${API_URL.LHSPendingForVehichleArrivalHierarchical}`, { 
            params: queryParams , withCredentials : true })
        .then((res) => {
            resolve(res.data);
        })
        .catch((error) => {
            reject(error.response.status);
        });
    });
}

export async function getLHSPendingForVehichleArrival(queryParams) {
    return new Promise((resolve, reject) => {
        axios
        .get(`${baseUrl}${API_URL.LHSPendingForVehichleArrival}`, { 
            params: queryParams , withCredentials : true })
        .then((res) => {
            resolve(res.data);
        })
        .catch((error) => {
            reject(error.response.status);
        });
    });
}

// Inward LHS Pending for vehicle Unloading

export async function getLHSPendingForVehichleUnloadingHierarchical(queryParams) {
    return new Promise((resolve, reject) => {
        axios
        .get(`${baseUrl}${API_URL.LHSPendingForVehichleUnloadingHierarchical}`, { 
            params: queryParams , withCredentials : true })
        .then((res) => {
            resolve(res.data);
        })
        .catch((error) => {
            reject(error.response.status);
        });
    });
}

export async function getLHSPendingForVehichleUnloading(queryParams) {
    return new Promise((resolve, reject) => {
        axios
        .get(`${baseUrl}${API_URL.LHSPendingForVehichleUnloading}`, { 
            params: queryParams , withCredentials : true })
        .then((res) => {
            resolve(res.data);
        })
        .catch((error) => {
            reject(error.response.status);
        });
    });
}